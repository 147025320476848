type CSSStyleKeys = {
  [K in keyof CSSStyleDeclaration]: CSSStyleDeclaration[K] extends string
    ? K
    : never;
}[keyof CSSStyleDeclaration];

const setStyles = (
  target: HTMLElement,
  css: Partial<Record<CSSStyleKeys, string>>
): void => {
  Object.entries(css).forEach(([property, value]) => {
    if (value !== undefined) {
      target.style[property as CSSStyleKeys] = value;
    }
  });
};

const removeProperties = (target: HTMLElement, properties: string[]): void => {
  properties.forEach((property) => target.style.removeProperty(property));
};

const slideUp = (target: HTMLElement, duration: number = 300): void => {
  const sliding: number = window.setInterval(() => {
    if (!target.classList.contains("sliding")) {
      target.classList.add("sliding");
      setStyles(target, {
        transitionProperty: "height, margin, padding",
        transitionDuration: duration + "ms",
        boxSizing: "border-box",
        height: target.offsetHeight + "px",
      });
      void target.offsetHeight;
      setStyles(target, {
        overflow: "hidden",
        height: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        marginTop: "0px",
        marginBottom: "0px",
      });
      window.setTimeout(() => {
        target.style.display = "none";
        removeProperties(target, [
          "height",
          "padding-top",
          "padding-bottom",
          "margin-top",
          "margin-bottom",
          "overflow",
          "transition-duration",
          "transition-property",
        ]);
        target.classList.remove("sliding");
      }, duration);
      clearInterval(sliding);
    }
  }, 50);
};

const slideDown = (target: HTMLElement, duration: number = 300): void => {
  const sliding: number = window.setInterval(() => {
    if (!target.classList.contains("sliding")) {
      target.classList.add("sliding");
      removeProperties(target, ["display"]);

      let display: string = window.getComputedStyle(target).display;

      if (display === "none") {
        display = "block";
      }

      setStyles(target, { display });

      const height: number = target.offsetHeight;

      setStyles(target, {
        overflow: "hidden",
        height: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        marginTop: "0px",
        marginBottom: "0px",
      });

      void target.offsetHeight;

      setStyles(target, {
        boxSizing: "border-box",
        transitionProperty: "height, margin, padding",
        transitionDuration: duration + "ms",
        height: height + "px",
      });
      removeProperties(target, [
        "padding-top",
        "padding-bottom",
        "margin-top",
        "margin-bottom",
      ]);
      window.setTimeout(() => {
        removeProperties(target, [
          "height",
          "overflow",
          "transition-duration",
          "transition-property",
        ]);
        target.classList.remove("sliding");
      }, duration);
      clearInterval(sliding);
    } else {
      clearInterval(sliding);
    }
  }, 50);
};

const slideToggle = (target: HTMLElement, duration: number = 300): void => {
  const display: string = window.getComputedStyle(target).display;

  if (display === "none") {
    slideDown(target, duration);
  } else {
    slideUp(target, duration);
  }
};

const fadeIn = (target: HTMLElement, speed: number = 300): void => {
  setStyles(target, { opacity: "0" });
  let opacity: number = 0;
  const coefficient: number = 1 / (speed / 30);
  const timer: number = window.setInterval(() => {
    if (opacity >= 1) {
      clearInterval(timer);
      return;
    }

    opacity += coefficient;
    setStyles(target, { opacity: opacity.toString() });
  }, 1);
};

const fadeOut = (
  target: HTMLElement,
  speed: number = 300,
  remove: boolean = false
): void => {
  setStyles(target, { opacity: "1" });
  let opacity: number = 1;
  const coefficient: number = 1 / (speed / 30);
  const timer: number = window.setInterval(() => {
    if (opacity <= coefficient) {
      clearInterval(timer);

      if (!remove) {
        setStyles(target, { display: "none" });
      }

      return;
    }

    opacity -= coefficient;
    setStyles(target, { opacity: opacity.toString() });
  }, 1);

  if (remove) {
    setTimeout(() => {
      target.remove();
    }, speed);
  }
};

export { slideDown, slideUp, slideToggle, setStyles, fadeOut, fadeIn };
