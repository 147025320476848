"use client";

import type { FC } from "react";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "@/hooks";
import { useModal } from "./context";
import type { CloseButtonProps } from "./Modal.types";
import { CloseIcon } from "@/components/common/Icon";
import { isArray, isEmptyArray, scroll } from "@/utils";
import styles from "./Modal.module.scss";

const CloseButton: FC<CloseButtonProps> = ({ id, onClose }) => {
  const { removeModal } = useModal();

  const handleClose = () => {
    removeModal(id);

    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={styles["modal__close-button"]} onClick={handleClose}>
      <CloseIcon color="#AFAFAF" />
    </div>
  );
};

export const ModalPortal: FC = () => {
  const { modals } = useModal();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const isMobileWidth = useMediaQuery(768);

  const handleExitComplete = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (!isVisible && isArray(modals) && !isEmptyArray(modals)) {
      setIsVisible(true);
      scroll.lock();
    }

    if (!isVisible && isArray(modals) && isEmptyArray(modals)) {
      scroll.unlock();
    }
  }, [modals, isVisible]);

  useEffect(() => {
    if (isVisible) {
      const videos = document.querySelectorAll("video");

      videos.forEach((video) => {
        const videoElement = video as HTMLVideoElement;

        if (!video.closest("#modal-portal")) {
          videoElement.pause();
        }
      });
    }

    return () => {
      const autoplayVideos = document.querySelectorAll("video[autoplay]");

      autoplayVideos.forEach((video) => {
        const videoElement = video as HTMLVideoElement;
        videoElement.play();
      });
    };
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div id="modal-portal" className={styles["modal-portal"]}>
      <AnimatePresence onExitComplete={handleExitComplete}>
        {isArray(modals) && !isEmptyArray(modals) && (
          <motion.div
            className={styles["modal__backdrop"]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
        {modals?.map((modal) => (
          <motion.div
            key={modal.id}
            className={`${styles["modal"]} ${
              modal.fullWidth ? styles["modal--full-width"] : ""
            } ${modal.fullscreen ? styles["modal--fullscreen"] : ""}`}
            initial={isMobileWidth ? { y: "100%" } : { opacity: 0 }}
            animate={isMobileWidth ? { y: 0 } : { opacity: 1 }}
            exit={isMobileWidth ? { y: "100%" } : { opacity: 0 }}
            transition={{
              type: "tween",
              duration: 0.3,
            }}
          >
            {modal.content}
            <CloseButton id={modal.id} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};
