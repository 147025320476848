"use client";

import type { FC, MouseEvent } from "react";
import { Children, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { Slider, useModal } from "@/components/common";
import { v4 as uuidv4 } from "uuid";
import type { GalleryProps } from "./Gallery.types";

export const Gallery: FC<GalleryProps> = ({
  children,
  fullscreen = false,
  navigation = "none",
  swiperOptions = {},
  aspectRatio,
  onClick,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { addModal } = useModal();
  const modalId = uuidv4();

  const defaultSwiperOptions = {
    navigation: true,
    enabled: true,
    slidesPerView: 1,
    pagination: true,
    centeredSlides: true,
    loop: true,
    autoplay: {
      enabled: true,
      delay: 5000,
    },
  };

  const handleOnClick = (e: MouseEvent<HTMLElement>, index: number) => {
    setActiveIndex(index);

    if (fullscreen === true) {
      addModal({
        id: modalId,
        fullscreen: true,
        content: (
          <Slider
            options={{
              ...defaultSwiperOptions,
              initialSlide: activeIndex,
            }}
            className="swiper-container--fullscreen"
            NavigationProps={{
              variant: "circle",
            }}
          >
            {Children.map(children, (child, idx) => (
              <SwiperSlide key={idx} className="swiper-slide">
                {child}
              </SwiperSlide>
            ))}
          </Slider>
        ),
      });
    } else if (typeof onClick === "function") {
      onClick(e);
    }
  };

  const slides = Children.map(children, (child, index) =>
    child ? (
      <SwiperSlide
        key={index}
        onClick={(e) => handleOnClick(e, index)}
        style={aspectRatio ? { aspectRatio } : {}}
        className="swiper-slide"
      >
        {child}
      </SwiperSlide>
    ) : null
  );

  if (!slides?.length) {
    return null;
  }

  return (
    <Slider
      options={{ ...defaultSwiperOptions, ...swiperOptions }}
      className="swiper-container--gallery"
      NavigationProps={{ variant: navigation }}
    >
      {slides}
    </Slider>
  );
};
