"use client";

import type { FC } from "react";
import { useEffect, useState } from "react";
import {
  LogoLink,
  LanguageSwitcher,
  CurrencySwitcher,
  Image,
} from "@/components/common";
import { useApp } from "@/contexts/app";
import styles from "./Header.module.scss";
import { prepareResponsiveMediaList } from "../Image/utils";
import { isEmptyArray } from "@/utils";

export const Header: FC = () => {
  const { app } = useApp();
  const [isStickied, setIsStickied] = useState<boolean>(false);

  const scope = isStickied ? "footer" : "header";
  const responsiveLogo = prepareResponsiveMediaList(app?.logos?.[scope]);

  const handleScroll = () => {
    const offset = window.scrollY;

    setIsStickied(offset >= 1);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`${styles.header} ${isStickied ? styles["is-stickied"] : ""}`}
    >
      <div className="container">
        {!isEmptyArray(responsiveLogo.desktop) && (
          <LogoLink>
            <picture>
              <source
                srcSet={responsiveLogo.mobile[0].src}
                media="(max-width: 480px)"
              />
              <source
                srcSet={responsiveLogo.tablet[0].src}
                media="(max-width: 768px)"
              />
              <Image
                className={styles.logo}
                {...responsiveLogo.desktop[0]}
                lazy={false}
              />
            </picture>
          </LogoLink>
        )}
        <div className={styles.switchers}>
          <LanguageSwitcher />
          <CurrencySwitcher />
        </div>
      </div>
    </header>
  );
};
