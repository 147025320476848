import type { FC } from "react";
import { useCallback, useMemo, useReducer } from "react";
import { I18nContext, defaultI18nState } from "./I18nContext";
import { I18nReducer } from "./I18nReducer";
import type { I18nProviderProps } from "./I18nContext.types";
import type { Locale, Translation } from "@/types";

export const I18nProvider: FC<I18nProviderProps> = ({
  locales,
  locale,
  translations,
  children,
}) => {
  const overrides = Object.fromEntries(
    Object.entries({
      locales,
      locale,
      translations,
    }).filter(([, value]) => value !== undefined)
  );

  const [state, dispatch] = useReducer(I18nReducer, {
    ...defaultI18nState,
    ...overrides,
  });

  const setLocales = useCallback((locales: Locale[]) => {
    dispatch({ type: "SET_LOCALES", payload: locales });
  }, []);

  const setLocale = useCallback((locale: Locale) => {
    dispatch({ type: "SET_LOCALE", payload: locale });
  }, []);

  const setTranslations = useCallback((translations: Translation[]) => {
    dispatch({ type: "SET_TRANSLATIONS", payload: translations });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setLocales,
      setLocale,
      setTranslations,
    }),
    [state, setLocales, setLocale, setTranslations]
  );

  return (
    <I18nContext.Provider value={contextValue}>{children}</I18nContext.Provider>
  );
};
