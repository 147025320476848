"use client";

import type { AnchorHTMLAttributes, FC } from "react";
import { useMemo } from "react";
import { useCurrencies } from "@/contexts/currencies";
import { useI18n } from "@/contexts/i18n";
import { router } from "@/utils";

export const LogoLink: FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  href,
  children,
  ...props
}) => {
  const { currency } = useCurrencies();
  const { locale } = useI18n();

  const computedHref = useMemo(() => {
    if (href) {
      return href;
    }

    const segments: string[] = [];

    if (!locale.attributes.is_default) {
      segments.push(locale.attributes.slug);
    }

    if (!currency.attributes.is_default) {
      segments.push(currency.attributes.code.toLowerCase());
    }

    return router.normalizePath(`/${segments.join("/")}`);
  }, [href, locale, currency]);

  return (
    <a href={computedHref} {...props}>
      {children}
    </a>
  );
};
