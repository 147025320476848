import type { FC } from "react";
import type { StaticImageData } from "next/image";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import Image from "next/image";
import mapMarker from "@/public/images/icons/map_marker.svg";
import mapMarkerActive from "@/public/images/icons/map_marker_active.svg";
import type { MarkerProps } from "./GoogleMap.types";

export const Marker: FC<MarkerProps> = ({ position, isActive, onClick }) => {
  const markerIcon = (
    isActive ? mapMarkerActive : mapMarker
  ) as StaticImageData;

  return (
    <AdvancedMarker position={position} onClick={onClick}>
      <Image
        src={markerIcon.src}
        width={markerIcon.width}
        height={markerIcon.height}
        alt="Map Marker"
      />
    </AdvancedMarker>
  );
};
