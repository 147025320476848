import type {
  AccordionAction,
  AccordionContextState,
} from "./AccordionContext.types";

export const AccordionReducer = (
  state: AccordionContextState,
  action: AccordionAction
): AccordionContextState => {
  const { type, payload } = action;

  switch (type) {
    case "TOGGLE_ACCORDION":
      return { ...state, expandedId: payload };

    default:
      return state;
  }
};
