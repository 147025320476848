import type { FC } from "react";
import { useCallback, useMemo, useReducer } from "react";
import { AppContext, defaultAppState } from "./AppContext";
import { AppReducer } from "./AppReducer";
import type { App, AppProviderProps } from "./AppContext.types";

export const AppProvider: FC<AppProviderProps> = ({
  isLoading,
  app,
  children,
}) => {
  const overrides = Object.fromEntries(
    Object.entries({
      isLoading,
      app,
    }).filter(([, value]) => value !== undefined)
  );

  const [state, dispatch] = useReducer(AppReducer, {
    ...defaultAppState,
    ...overrides,
  });

  const setIsLoading = useCallback((isLoading: boolean) => {
    dispatch({ type: "SET_IS_LOADING", payload: isLoading });
  }, []);

  const setApp = useCallback((app: App | undefined) => {
    dispatch({ type: "SET_APP", payload: app });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setIsLoading,
      setApp,
    }),
    [state, setIsLoading, setApp]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
