import { FC, useCallback, useEffect, useMemo, useReducer } from "react";
import { SliderContext, defaultSliderState } from "./SliderContext";
import { SliderReducer } from "./SliderReducer";
import { SliderProviderProps } from "./SliderContext.types";
import type { Swiper as SwiperProps } from "swiper/types";

export const SliderProvider: FC<SliderProviderProps> = ({
  swiper,
  children,
}) => {
  const overrides = Object.fromEntries(
    Object.entries({
      swiper,
    }).filter(([, value]) => value !== undefined)
  );
  const [state, dispatch] = useReducer(SliderReducer, {
    ...defaultSliderState,
    ...overrides,
  });

  const setSwiper = useCallback((swiper: SwiperProps | undefined) => {
    dispatch({ type: "SET_SWIPER", payload: swiper });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setSwiper,
    }),
    [state, setSwiper]
  );

  useEffect(() => {
    if (swiper) {
      setSwiper(swiper);
    }
  }, [swiper]);

  return (
    <SliderContext.Provider value={contextValue}>
      {children}
    </SliderContext.Provider>
  );
};
