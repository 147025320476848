"use client";

import type { ImageProps } from "next/image";
import NextImage from "next/image";
import { normalizeProps } from "./utils";
import { LazyImageLoader } from "./LazyImageLoader";
import { Placeholder } from "./Placeholder";

export const Image = (
  props: ImageProps & { base64?: string; lazy?: boolean }
) => {
  const normalizedProps = normalizeProps(props);

  if (!normalizedProps.src) {
    return null;
  }

  if (!props.lazy) {
    const notLazyProps = normalizeProps(normalizedProps, [
      "blurDataURL",
      "placeholder",
    ]);

    return <NextImage {...notLazyProps} />;
  }

  return (
    <LazyImageLoader>
      {(isVisible, ref) =>
        isVisible ? (
          <NextImage {...normalizedProps} />
        ) : (
          <Placeholder
            ref={ref}
            blurDataURL={normalizedProps.blurDataURL}
            width={normalizedProps.width}
            height={normalizedProps.height}
          />
        )
      }
    </LazyImageLoader>
  );
};

Image.displayName = "Image";
