"use client";

import type { FC, IframeHTMLAttributes } from "react";
import { useState } from "react";
import { CircleLoaderIcon } from "@/components/common/Icon";

export const Iframe: FC<IframeHTMLAttributes<HTMLIFrameElement>> = ({
  src,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      {!isLoaded && (
        <div
          className="window-preloader"
          style={{
            position: "absolute",
            inset: 0,
            display: "inline-flex",
          }}
        >
          <CircleLoaderIcon
            width="100px"
            height="100px"
            style={{ margin: "auto" }}
          />
        </div>
      )}
      <iframe
        src={src}
        width="100%"
        height="100%"
        onLoad={() => setIsLoaded(true)}
      />
    </>
  );
};
