import { get } from "@/utils/lodash";
import type { Locale, Currency } from "@/types";

export class I18nService {
  public locale: Locale;
  public currency: Currency;
  public pathname: string = "/";
  public basePathname: string = "/";

  private locales: Locale[];
  private currencies: Currency[];
  private url?: URL;

  private localeAttributeKey = "attributes.slug";
  private currencyAttributeKey = "attributes.code";

  public static readonly localeFallback: Locale = {
    type: "locales",
    id: "0",
    attributes: {
      name: "English",
      slug: "en",
      locale: "en-US",
      is_default: true,
    },
  };

  public static readonly currencyFallback: Currency = {
    type: "currencies",
    id: "0",
    attributes: {
      name: "United Arab Emirates Dirham",
      code: "AED",
      symbol: "د.إ",
      is_platinumlist: true,
      is_default: true,
    },
  };

  /**
   * initializes the service with locales, currencies, an optional url, and cookie values.
   *
   * @param locales list of locales.
   * @param currencies list of currencies.
   * @param url optional url string.
   * @param cookielocale locale value from cookie.
   * @param cookiecurrency currency value from cookie.
   */
  constructor(
    locales: Locale[],
    currencies: Currency[],
    url?: string,
    cookieLocale?: string,
    cookieCurrency?: string
  ) {
    this.locales = locales.length ? locales : [I18nService.localeFallback];
    this.currencies = currencies.length
      ? currencies
      : [I18nService.currencyFallback];
    this.locale = this.getDefaultLocale();
    this.currency = this.getDefaultCurrency();

    if (url) {
      try {
        this.url = new URL(url);
      } catch (error) {
        console.error("invalid url provided:", url, error);
      }

      if (this.url) {
        this.locale = this.computeDesiredItem<Locale>(
          this.locales,
          this.localeAttributeKey,
          cookieLocale,
          I18nService.localeFallback
        );
        this.currency = this.computeDesiredItem<Currency>(
          this.currencies,
          this.currencyAttributeKey,
          cookieCurrency,
          I18nService.currencyFallback
        );
        this.buildPathname();
      }
    }
  }

  /**
   * retrieves an item from the cookie based on the provided value.
   */
  private getFromCookie<T>(
    items: T[],
    attributeKey: string,
    cookieValue?: string
  ): T | undefined {
    if (!cookieValue) {
      return undefined;
    }

    return items.find((item) => get(item, attributeKey) === cookieValue);
  }

  /**
   * retrieves an item from the URL based on the provided attribute.
   */
  private getFromUrl<T>(
    items: T[],
    attributeKey: string,
    url: string
  ): T | undefined {
    if (!url || !items || !Array.isArray(items)) {
      return undefined;
    }

    const availableKeys = items.map((item) => {
      const value = get(item, attributeKey);

      return typeof value === "string" ? value.toLowerCase() : "";
    });
    const urlRegex = new RegExp(`\/(${availableKeys.join("|")})(\/|$)`);
    const match = url.match(urlRegex);

    if (match) {
      return items.find((item) => {
        const value = get(item, attributeKey);

        if (typeof value === "string") {
          return value.toLowerCase() === match[1];
        }

        return false;
      });
    }

    return undefined;
  }

  /**
   * returns the default item from the list or the fallback.
   */
  private getDefault<T>(items: T[], fallbackItem: T): T {
    return (
      items.find((item) => get(item, "attributes.is_default")) || fallbackItem
    );
  }

  /**
   * checks if the given value exists in any item within the list.
   */
  private isValid<T>(
    items: T[],
    attributeKey: string,
    value: T | string | undefined
  ): boolean {
    return items.some((item) => get(item, attributeKey) === value);
  }

  /**
   * determines the desired item based on cookie and url values.
   * splits the logic into helper methods for better readability.
   *
   * logic:
   * 1. if a valid cookie value exists and url does not contain a corresponding segment, use the cookie value.
   * 2. if both cookie and url have valid values, check if they match:
   *    - if they match, action is "none".
   *    - if they differ, a redirect is required.
   * 3. if the cookie is valid but url is present and invalid, use the default item with a redirect.
   * 4. if only url has a valid value, return the url value with a "setcookie" action.
   * 5. otherwise, return the default item with a "setcookie" action.
   *
   * @param items array of items.
   * @param attributekey attribute key to compare.
   * @param defaultitem default item.
   * @param itemfromurl item extracted from the url (if any).
   * @param itemfromcookie item extracted from the cookie (if any).
   */
  private determineDesiredItem<T>(
    items: T[],
    attributeKey: string,
    defaultItem: T,
    itemFromUrl: T | undefined,
    itemFromCookie: T | undefined
  ): T {
    const cookieValue = itemFromCookie
      ? get(itemFromCookie, attributeKey)
      : undefined;
    const urlValue = itemFromUrl ? get(itemFromUrl, attributeKey) : undefined;

    const isCookieValid =
      !!itemFromCookie && this.isValid(items, attributeKey, cookieValue);
    const isUrlValid =
      !!itemFromUrl && this.isValid(items, attributeKey, urlValue);

    if (isCookieValid && !itemFromUrl) {
      return itemFromCookie;
    }

    if (isCookieValid && isUrlValid) {
      return itemFromCookie;
    }

    if (isCookieValid) {
      return defaultItem;
    }

    if (itemFromUrl && isUrlValid) {
      return itemFromUrl;
    }

    return defaultItem;
  }

  /**
   * computes the desired item using cookie and URL data.
   */
  private computeDesiredItem<T>(
    items: T[],
    attributeKey: string,
    cookieValue: string | undefined,
    fallbackItem: T
  ): T {
    const url = this.url?.href || "/";
    const itemFromCookie = this.getFromCookie(items, attributeKey, cookieValue);
    const itemFromUrl = this.getFromUrl(items, attributeKey, url);
    const defaultItem = this.getDefault(items, fallbackItem);

    return this.determineDesiredItem(
      items,
      attributeKey,
      defaultItem,
      itemFromUrl,
      itemFromCookie
    );
  }

  /**
   * builds a new pathname based on the desired locale and currency.
   */
  private buildPathname(): void {
    if (!this.url) {
      this.basePathname = "/";
      this.pathname = "/";

      return;
    }

    const currentPathname = this.url.pathname;
    const availableLocaleKeys = this.locales.map((item) => {
      const value = get(item, this.localeAttributeKey);

      return typeof value === "string" ? value.toLowerCase() : "";
    });
    const availableCurrencyKeys = this.currencies.map((item) => {
      const value = get(item, this.currencyAttributeKey);

      return typeof value === "string" ? value.toLowerCase() : "";
    });

    const allKeys = [...availableLocaleKeys, ...availableCurrencyKeys];

    const segments = currentPathname.split("/").filter(Boolean);
    const clearedSegments = segments.filter(
      (segment) => !allKeys.includes(segment)
    );

    this.basePathname = "/" + clearedSegments.join("/");

    if (!this.currency.attributes.is_default) {
      const code = get(this.currency, this.currencyAttributeKey);

      if (typeof code === "string") {
        clearedSegments.unshift(code.toLowerCase());
      }
    }

    if (!this.locale.attributes.is_default) {
      const slug = get(this.locale, this.localeAttributeKey);

      if (typeof slug === "string") {
        clearedSegments.unshift(slug.toLowerCase());
      }
    }

    this.pathname = "/" + clearedSegments.join("/");
  }

  /**
   * returns the default locale.
   */
  public getDefaultLocale(): Locale {
    return this.getDefault(this.locales, I18nService.localeFallback);
  }

  /**
   * returns the default currency.
   */
  public getDefaultCurrency(): Currency {
    return this.getDefault(this.currencies, I18nService.currencyFallback);
  }

  /**
   * finds a locale by attribute, or returns the default locale if not found.
   */
  public getLocaleByAttribute(key: string, value: string): Locale {
    const found = this.locales.find((locale) => get(locale, key) === value);

    return found || this.getDefaultLocale();
  }

  /**
   * finds a currency by attribute, or returns the default currency if not found.
   */
  public getCurrencyByAttribute(key: string, value: string): Currency {
    const found = this.currencies.find(
      (currency) => get(currency, key) === value
    );

    return found || this.getDefaultCurrency();
  }
}
