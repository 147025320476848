"use client";

import { MouseEvent, forwardRef } from "react";
import nprogress from "nprogress";
import { addBasePath } from "next/dist/client/add-base-path";
import NextLink from "next/link";
import { LinkProps } from "./Link.types";

const getURL = (href: string): URL => {
  return new URL(addBasePath(href), location.href);
};

const isModifiedEvent = (event: MouseEvent): boolean => {
  const eventTarget = event.currentTarget as HTMLAnchorElement | SVGAElement;
  const target = eventTarget.getAttribute("target");

  return (
    (target && target !== "_self") ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey ||
    (event.nativeEvent && event.nativeEvent.button === 1)
  );
};

const shouldTriggerStartEvent = (href: string, clickEvent?: MouseEvent) => {
  const current = window.location;
  const target = getURL(href);

  if (clickEvent && isModifiedEvent(clickEvent)) {
    return false;
  }

  if (current.origin !== target.origin) {
    return false;
  }

  if (
    current.pathname === target.pathname &&
    current.search === target.search
  ) {
    return false;
  }

  return true;
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function link(
  { href, scroll, replace, shallow, onClick, ...rest },
  ref
) {
  const useLink = href && href.startsWith("/");

  if (!useLink) {
    return <a href={href} onClick={onClick} {...rest} />;
  }

  return (
    <NextLink
      ref={ref}
      href={href}
      scroll={scroll}
      replace={replace}
      onClick={(event) => {
        if (shouldTriggerStartEvent(href, event)) {
          nprogress.start();
        }

        if (onClick) {
          onClick(event);
        }
      }}
      prefetch={false}
      shallow={shallow}
      {...rest}
    />
  );
});
