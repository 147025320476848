"use client";

import type { FC } from "react";
import { useEffect, useRef, useState, useCallback } from "react";
import type { ScrollWrapperProps } from "./ScrollWrapper.types";
import styles from "./ScrollWrapper.module.scss";

const getAbsoluteHeight = (element: HTMLElement | null): number => {
  if (!element) {
    return 0;
  }

  const computedStyles = window.getComputedStyle(element);
  const marginTop = parseFloat(computedStyles.marginTop);
  const marginBottom = parseFloat(computedStyles.marginBottom);

  return element.offsetHeight + marginTop + marginBottom;
};

export const ScrollWrapper: FC<ScrollWrapperProps> = ({
  children,
  containerSelector,
  subtractionSelectors = [],
  additionalSubtraction = 0,
}) => {
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  const updateMaxHeight = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const container = ref.current.closest(containerSelector) as HTMLElement;

    if (!container) {
      return;
    }

    const containerStyles = window.getComputedStyle(container);
    let containerHeight =
      container.offsetHeight -
      parseFloat(containerStyles.paddingTop) -
      parseFloat(containerStyles.paddingBottom) -
      additionalSubtraction;

    subtractionSelectors.forEach((selector) => {
      const element = container.querySelector(selector) as HTMLElement;

      if (element) {
        containerHeight -= getAbsoluteHeight(element);
      }
    });

    setMaxHeight(containerHeight);
  }, [containerSelector, subtractionSelectors, additionalSubtraction]);

  useEffect(() => {
    const container = ref.current?.closest(containerSelector) as HTMLElement;

    if (!container) {
      return;
    }

    updateMaxHeight();

    const resizeObserver = new ResizeObserver(() => {
      updateMaxHeight();
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.disconnect();
      }
    };
  }, [containerSelector, updateMaxHeight]);

  return (
    <div
      ref={ref}
      className={styles["scroll-wrapper"]}
      style={{ maxHeight: maxHeight }}
      data-scrollable="true"
    >
      {children}
    </div>
  );
};
