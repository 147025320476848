import type { FC } from "react";
import type { AccordionItemProps } from "./Accordion.types";
import { useAccordion } from "./context";
import styles from "./Accordion.module.scss";

export const AccordionItem: FC<AccordionItemProps> = ({ children, id }) => {
  const { expandedId } = useAccordion();
  const isExpanded = expandedId === id;

  return (
    <div
      className={`${styles["accordion-item"]} ${
        isExpanded ? styles["accordion-item--expanded"] : ""
      }`}
    >
      {children}
    </div>
  );
};
