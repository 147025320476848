import { useRef, useEffect } from "react";

export const useAfterInitialRender = <T extends unknown[]>(
  callback: () => Promise<void> | void,
  dependencies: T
): boolean => {
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      void (async () => {
        await callback();
      })();
    }
  }, dependencies);

  return isInitialRender.current;
};
