"use client";

import { createContext, useContext } from "react";
import type {
  CurrenciesContextProps,
  CurrenciesContextState,
} from "./CurrenciesContext.types";
import { I18nService } from "@/services/I18nService";

export const defaultCurrenciesState: CurrenciesContextState = {
  currencies: [I18nService.currencyFallback],
  currency: I18nService.currencyFallback,
  defaultCurrency: I18nService.currencyFallback,
};

export const CurrenciesContext = createContext<CurrenciesContextProps | null>(
  null
);

export const useCurrencies = () => {
  const context = useContext<CurrenciesContextProps | null>(CurrenciesContext);

  if (!context) {
    throw new Error("useCurrencies must be used within a CurrenciesProvider");
  }

  return context;
};
