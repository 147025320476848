import type { FC } from "react";
import { useCallback, useMemo, useReducer } from "react";
import { ModalContext, defaultModalState } from "./ModalContext";
import { ModalReducer } from "./ModalReducer";
import type { ModalProps, ModalProviderProps } from "./ModalContext.types";
import { ModalPortal } from "../Modal";

export const ModalProvider: FC<ModalProviderProps> = ({
  modals = [],
  children,
}) => {
  const overrides = Object.fromEntries(
    Object.entries({
      modals,
    }).filter(([, value]) => value !== undefined)
  );

  const [state, dispatch] = useReducer(ModalReducer, {
    ...defaultModalState,
    ...overrides,
  });

  const addModal = useCallback((modal: ModalProps) => {
    dispatch({ type: "ADD_MODAL", payload: modal });
  }, []);

  const removeModal = useCallback((id: ModalProps["id"]) => {
    dispatch({ type: "REMOVE_MODAL", payload: id });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      addModal,
      removeModal,
    }),
    [state, addModal, removeModal]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      <ModalPortal />
    </ModalContext.Provider>
  );
};
