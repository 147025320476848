import type { SliderAction, SliderContextState } from "./SliderContext.types";

export const SliderReducer = (
  state: SliderContextState,
  action: SliderAction
): SliderContextState => {
  const { type, payload } = action;

  switch (type) {
    case "SET_SWIPER":
      return { ...state, swiper: payload };

    default:
      return state;
  }
};
