"use client";

import type {
  FC,
  ReactElement,
  ButtonHTMLAttributes,
  MouseEvent,
  MouseEventHandler,
} from "react";
import { useState, useRef, cloneElement } from "react";
import styles from "./Dropdown.module.scss";
import {
  DropdownProps,
  DropdownItemProps,
  DropdownContentProps,
} from "./Dropdown.types";
import { useOutsideClick, usePositioning } from "@/hooks";
import { ExpandMoreIcon } from "../Icon";

export const Dropdown: FC<DropdownProps> = ({
  toggleComponent,
  placement = "bottom",
  isVisible: controlledIsVisible,
  onVisibilityChange,
  children,
  ...props
}) => {
  const [uncontrolledIsVisible, setUncontrolledIsVisible] = useState(false);
  const isVisible =
    controlledIsVisible !== undefined
      ? controlledIsVisible
      : uncontrolledIsVisible;
  const setIsVisible = (value: boolean) => {
    if (controlledIsVisible === undefined) {
      setUncontrolledIsVisible(value);
    }
    onVisibilityChange?.(value);
  };

  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const toggleComponentRef = useRef<HTMLDivElement | null>(null);
  const position = usePositioning(
    toggleComponentRef,
    dropdownRef,
    isVisible,
    8,
    placement
  );

  useOutsideClick(dropdownContainerRef, () => setIsVisible(false));

  const toggleDropdown = (event: MouseEvent) => {
    event.stopPropagation();
    setIsVisible(!isVisible);
  };

  return (
    <div
      ref={dropdownContainerRef}
      className={styles["dropdown-container"]}
      {...props}
    >
      <div
        ref={toggleComponentRef}
        className={`${styles.dropdown__toggle} ${
          isVisible ? styles.visible : ""
        } dropdown__toggle`}
        onClick={toggleDropdown}
      >
        {cloneElement(
          toggleComponent as ReactElement<{ onClick: MouseEventHandler }>,
          { onClick: toggleDropdown }
        )}
      </div>
      <div
        ref={dropdownRef}
        className={`${styles.dropdown} ${
          isVisible ? styles.visible : styles.hidden
        }`}
        style={{
          top: position.top,
          left: position.left,
          minWidth: position.minWidth,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const DropdownContent: FC<DropdownContentProps> = ({
  children,
  ...props
}) => {
  return (
    <div className={styles["dropdown__content"]} {...props}>
      {children}
    </div>
  );
};

export const DropdownItem: FC<DropdownItemProps> = ({
  children,
  isActive,
  ...props
}) => {
  return (
    <div
      className={`${styles["dropdown__item"]} ${
        isActive ? styles["dropdown__item--is-active"] : ""
      } `}
      {...props}
    >
      {children}
    </div>
  );
};

export const DropdownItemButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }) => {
  return (
    <button className={styles["dropdown__button"]} {...props}>
      {children}
    </button>
  );
};

export const DropdownArrow: FC = () => {
  return (
    <ExpandMoreIcon
      width="6"
      height="7"
      className={styles["dropdown__arrow"]}
    />
  );
};
