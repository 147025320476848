import nextConfig from "next.config.mjs";

export interface Router {
  push: (path: string, scroll?: boolean) => void;
  replace: (path: string, scroll?: boolean) => void;
  normalizePath: (path: string) => string;
}

interface HistoryState {
  as?: string;
  url?: string;
  [key: string]: unknown;
}

const updateHistory = (
  path: string,
  method: "pushState" | "replaceState",
  scroll: boolean = true
): void => {
  const currentState: HistoryState = {
    ...((window.history.state as HistoryState) ?? {}),
  };
  const newUrl = new URL(path, window.location.origin).toString();

  window.history[method](
    { ...currentState, as: newUrl, url: newUrl },
    "",
    newUrl
  );

  if (scroll) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};

const push = (path: string, scroll: boolean = true): void => {
  updateHistory(path, "pushState", scroll);
};

const replace = (path: string, scroll: boolean = true): void => {
  updateHistory(path, "replaceState", scroll);
};

const normalizePath = (path: string): string => {
  const { trailingSlash = false } = nextConfig;
  let pathname = path.split("?")[0].split("#")[0];
  const search = path.includes("?")
    ? "?" + path.split("?")[1].split("#")[0]
    : "";
  const hash = path.includes("#") ? "#" + path.split("#")[1] : "";

  if (pathname === "/") {
    return pathname + search + hash;
  }

  if (trailingSlash && !pathname.endsWith("/")) {
    pathname += "/";
  } else if (!trailingSlash && pathname.endsWith("/")) {
    pathname = pathname.slice(0, -1);
  }

  return pathname + search + hash;
};

export const router: Router = {
  push,
  replace,
  normalizePath,
};
