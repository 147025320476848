export const get = <T = unknown>(
  obj: unknown,
  path: string,
  defaultValue?: T
): T | undefined => {
  if (obj === null || typeof path !== "string") {
    return defaultValue;
  }

  const result = path
    .replace(/\[(\d+)\]/g, ".$1")
    .split(".")
    .reduce<unknown>((acc, key) => {
      if (acc === null) {
        return undefined;
      }

      if (typeof acc === "object" || typeof acc === "function") {
        return (acc as Record<string, unknown>)[key];
      }

      return undefined;
    }, obj);

  return result === undefined ? defaultValue : (result as T);
};
