import type { FC } from "react";
import type { AccordionSummaryProps } from "./Accordion.types";
import { useAccordion } from "./context";
import { ExpandMoreIcon } from "@/components/common/Icon";
import styles from "./Accordion.module.scss";

export const AccordionSummary: FC<AccordionSummaryProps> = ({
  children,
  expandIcon = true,
  "aria-controls": ariaControls,
  id,
}) => {
  const { expandedId, toggleAccordion } = useAccordion();
  const isExpanded = expandedId === id;

  const handleExpandToggle = () => {
    toggleAccordion(!isExpanded ? id : null);
  };

  return (
    <div
      id={id}
      className={styles["accordion-summary"]}
      aria-controls={ariaControls}
      data-ga-event="click"
      data-ga-action={isExpanded ? "FAQ-Closefaq" : "FAQ-Openfaq"}
      onClick={handleExpandToggle}
    >
      <div className={styles["accordion-title"]}>{children}</div>
      {expandIcon && (
        <div className={styles["accordion-icon"]}>
          {typeof expandIcon === "boolean" ? (
            <ExpandMoreIcon style={{ color: "var(--text-secondary)" }} />
          ) : (
            expandIcon
          )}
        </div>
      )}
    </div>
  );
};
