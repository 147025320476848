"use client";

import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import {
  Map,
  MapCameraChangedEvent,
  useApiIsLoaded,
} from "@vis.gl/react-google-maps";
import { Loader } from "../Loader";
import { Marker } from "./Marker";
import type { GoogleMapProps } from "./GoogleMap.types";

export const GoogleMapWrapper: FC<GoogleMapProps> = ({
  markers = [],
  activeMarker = 0,
  activeMarkerCallback,
}) => {
  const [currentCenter, setCurrentCenter] = useState<google.maps.LatLngLiteral>(
    {
      lat: Number(markers[activeMarker]?.latitude) || 0,
      lng: Number(markers[activeMarker]?.longitude) || 0,
    }
  );

  const apiIsLoaded = useApiIsLoaded();

  const handleCameraChange = useCallback((event: MapCameraChangedEvent) => {
    setCurrentCenter(event.detail.center);
  }, []);

  useEffect(() => {
    setCurrentCenter({
      lat: Number(markers[activeMarker]?.latitude) || 0,
      lng: Number(markers[activeMarker]?.longitude) || 0,
    });
  }, [markers, activeMarker]);

  if (!apiIsLoaded) {
    return <Loader />;
  }

  return (
    <Map
      mapId="DEMO_MAP_ID"
      defaultZoom={15}
      center={currentCenter}
      onCameraChanged={handleCameraChange}
    >
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={{
            lat: Number(marker.latitude),
            lng: Number(marker.longitude),
          }}
          isActive={activeMarker === index}
          onClick={() => activeMarkerCallback(index)}
        />
      ))}
    </Map>
  );
};
