import type { FC, ReactElement } from "react";
import { Children, cloneElement, isValidElement } from "react";
import type { StepItemProps, StepListProps } from "./StepList.types";
import styles from "./StepList.module.scss";

export const StepList: FC<StepListProps> = ({
  current,
  vertical = false,
  style,
  children,
}) => {
  const validChildren = Children.toArray(children).filter(
    isValidElement
  ) as ReactElement<StepItemProps>[];
  const totalSteps = validChildren.length;

  return (
    <div
      className={`${styles["step-list"]} ${
        vertical ? styles["step-list--vertical"] : ""
      } step-list`}
      style={style}
    >
      {validChildren.map((child, index) =>
        cloneElement(child, {
          stepNumber: index + 1,
          isActive: current === index,
          showTail: index !== totalSteps - 1,
        })
      )}
    </div>
  );
};
