"use client";

import { createContext, useContext } from "react";
import type {
  AccordionContextProps,
  AccordionContextState,
} from "./AccordionContext.types";

export const defaultAccordionState: AccordionContextState = {
  expandedId: null,
};

export const AccordionContext = createContext<AccordionContextProps | null>(
  null
);

export const useAccordion = () => {
  const context = useContext<AccordionContextProps | null>(AccordionContext);

  if (!context) {
    throw new Error("useAccordion must be used within a AccordionProvider");
  }

  return context;
};
