import { createContext, useContext } from "react";
import type { I18nContextProps, I18nContextState } from "./I18nContext.types";
import { I18nService } from "@/services/I18nService";

export const defaultI18nState: I18nContextState = {
  locales: [I18nService.localeFallback],
  locale: I18nService.localeFallback,
  translations: [],
};

export const I18nContext = createContext<I18nContextProps | null>(null);

export const useI18n = () => {
  const context = useContext<I18nContextProps | null>(I18nContext);

  if (!context) {
    throw new Error("useI18n must be used within a I18nProvider");
  }

  return context;
};

export const getPlaceholders = (string: string): string[] => {
  const regex = /(%[a-zA-Z_]+%|%[ds])/g;
  const matches = string.match(regex);

  return matches || [];
};

export const normalizeString = (string: string): string => {
  if (typeof string !== "string") {
    return string;
  }

  return string.replace(/(\d+|%[a-zA-Z_]+%|%[ds])/g, "%d");
};

export const replacePlaceholders = (
  originalString: string,
  translatedString: string
): string => {
  if (typeof translatedString !== "string") {
    return originalString;
  }

  const placeholders = getPlaceholders(originalString);

  placeholders.forEach((placeholder) => {
    if (placeholder.match(/\d+/)) {
      translatedString = translatedString.replace("%d", placeholder);
    } else {
      translatedString = translatedString.replace("%s", placeholder);
    }
  });

  return translatedString;
};

export const useTranslation = () => {
  const { translations, locale } = useI18n();

  const t = (key: string, ...args: string[]): string => {
    if (typeof key !== "string" || !key) {
      return key;
    }

    const normalizedKey = normalizeString(key);
    const translation = translations.find(
      (t) =>
        normalizeString(t.attributes.original) === normalizedKey &&
        t.attributes.locale === locale.attributes?.locale
    );

    if (!translation) {
      return key;
    }

    let translatedString = translation.attributes.translation;

    // replace numbers first if any
    const numberMatch = key.match(/\d+/);

    if (numberMatch) {
      translatedString = translatedString.replace("%d", numberMatch[0]);
    }

    // replace other placeholders if args are provided
    if (args.length > 0) {
      args.forEach((arg) => {
        translatedString = translatedString.replace("%s", arg);
      });
    }

    return translatedString;
  };

  return { t };
};
