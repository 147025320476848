import { createContext, useContext } from "react";
import type { AppContextProps, AppContextState } from "./AppContext.types";

export const defaultAppState: AppContextState = {
  isLoading: false,
  app: undefined,
};

export const AppContext = createContext<AppContextProps | null>(null);

export const useApp = () => {
  const context = useContext<AppContextProps | null>(AppContext);

  if (!context) {
    throw new Error("useApp must be used within a AppProvider");
  }

  return context;
};
