import type { FC } from "react";
import type { ButtonListProps } from "./ButtonList.type";
import { isArray, isEmptyArray } from "@/utils";
import { Button } from "@/components/common";
import styles from "./ButtonList.module.scss";

export const ButtonList: FC<ButtonListProps> = ({
  buttons = [],
  className = "",
  ...props
}) => {
  if (!isArray(buttons) || isEmptyArray(buttons)) {
    return null;
  }

  return (
    <div
      className={`${styles["button-list"]} button-list ${className}`}
      {...props}
    >
      {buttons.map((button, index) => (
        <Button key={index} {...button} />
      ))}
    </div>
  );
};
