import { createContext, useContext } from "react";
import type {
  ModalContextProps,
  ModalContextState,
} from "./ModalContext.types";

export const defaultModalState: ModalContextState = {
  modals: [],
};

export const ModalContext = createContext<ModalContextProps | null>(null);

export const useModal = () => {
  const context = useContext<ModalContextProps | null>(ModalContext);

  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
};
