"use client";

import type { FC } from "react";
import { Children, useState, useEffect, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Thumbs } from "swiper/modules";
import type { SliderProps } from "./Slider.types";
import { Swiper as SwiperProps, SwiperOptions } from "swiper/types";
import { SliderProvider } from "./context";
import { isArray, isEmptyArray } from "@/utils";
import { SliderNavigation } from "./SliderNavigation";
import "swiper/css/bundle";
import "./styles.css";
import styles from "./Slider.module.scss";

export const Slider: FC<SliderProps> = ({
  children,
  options = {},
  className = "",
  tabs = [],
  classNameTabs = "",
  NavigationProps,
  onSlideChangeCallback,
}) => {
  const [swiper, setSwiper] = useState<SwiperProps | undefined>(undefined);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperProps | null>(null);

  const hasChildren = Children.count(children) > 0;
  const hasSingleChild = Children.count(children) === 1;

  const attributes: SwiperOptions = useMemo(() => {
    const baseAttributes: SwiperOptions = {
      spaceBetween: 10,
      slidesPerView: 1,
      modules: [Autoplay, Navigation, Pagination],
      ...options,
    };

    if (isArray(tabs) && !isEmptyArray(tabs)) {
      baseAttributes?.modules?.push(Thumbs);
      baseAttributes.thumbs = { swiper: thumbsSwiper };
    }

    if (hasSingleChild) {
      baseAttributes.loop = false;
    }

    // @ts-expect-error: typescript may not recognize autoplay.enabled correctly
    if (baseAttributes?.autoplay?.enabled) {
      // @ts-expect-error: typescript may not recognize autoplay.disableOnInteraction correctly
      baseAttributes.autoplay.disableOnInteraction = true;
    }

    return baseAttributes;
  }, [options, tabs, hasSingleChild, thumbsSwiper]);

  const [navigationDisabled, setNavigationDisabled] = useState<boolean>(
    !attributes.navigation
  );

  useEffect(() => {
    const updateNavigationState = () => {
      if (
        swiper?.currentBreakpoint === "max" ||
        typeof swiper?.currentBreakpoint === "object" ||
        typeof swiper?.currentBreakpoint === "undefined"
      ) {
        setNavigationDisabled(!attributes?.navigation);
      } else {
        const breakpointKey =
          swiper.currentBreakpoint as keyof typeof attributes.breakpoints;

        setNavigationDisabled(
          !attributes.breakpoints?.[breakpointKey]?.navigation
        );
      }
    };

    swiper?.on("breakpoint", updateNavigationState);
    updateNavigationState();

    return () => swiper?.off("breakpoint", updateNavigationState);
  }, [swiper, attributes]);

  const renderTabs = () => {
    if (!isArray(tabs) || isEmptyArray(tabs)) {
      return null;
    }

    return (
      <Swiper
        modules={[Thumbs]}
        watchSlidesProgress
        onSwiper={setThumbsSwiper}
        slidesPerView="auto"
        freeMode
        spaceBetween={16}
        className={`${styles["swiper-tabs"]} ${styles[classNameTabs]} swiper-tabs ${classNameTabs}`}
      >
        {tabs.map((tab, index) => (
          <SwiperSlide
            key={index}
            className={`${styles["swiper-tab"]} swiper-tab selection--disabled`}
          >
            {tab}
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  if (!hasChildren) {
    return null;
  }

  return (
    <SliderProvider swiper={swiper}>
      {renderTabs()}
      <div
        className={`${styles["swiper-container"]} ${styles[className]} swiper-container ${className}`}
      >
        <Swiper
          className={`${styles["swiper"]} swiper ${
            swiper?.isLocked ? "swiper--is-locked" : ""
          }`}
          onSwiper={setSwiper}
          onSlideChange={onSlideChangeCallback}
          {...attributes}
          navigation={false}
          data-scrollable="true"
        >
          {children}
        </Swiper>
        <SliderNavigation
          disabled={navigationDisabled || swiper?.isLocked === true}
          {...NavigationProps}
        />
      </div>
    </SliderProvider>
  );
};
