"use client";

import type { FC } from "react";
import { useState } from "react";
import {
  Dropdown,
  DropdownArrow,
  DropdownContent,
  DropdownItem,
} from "../Dropdown";
import type { Locale } from "@/types";
import { useI18n } from "@/contexts/i18n";
import { LanguageIcon } from "@/components/common/Icon";
import { useSetCookie } from "cookies-next";
import { I18nService } from "src/services/I18nService";
import { useCurrencies } from "@/contexts/currencies";
import { getCookieDomain, isArray, router } from "@/utils";

export const LanguageSwitcher: FC = () => {
  const { locales, locale } = useI18n();
  const { currencies, currency } = useCurrencies();
  const [isVisible, setIsVisible] = useState(false);
  const setCookie = useSetCookie();

  const handleChangeLocale = (selectedLocale: Locale) => {
    if (locale.id === selectedLocale.id) {
      return;
    }

    setIsVisible(false);

    if (typeof window !== "undefined") {
      const cookieDomain = getCookieDomain(window.location.hostname);
      const i18nService = new I18nService(
        locales || [],
        currencies || [],
        window.location.href,
        selectedLocale.attributes.slug,
        currency.attributes.code
      );

      const normalizedNew = router.normalizePath(i18nService.pathname);

      setCookie("user_lang", selectedLocale.attributes.slug, {
        domain: cookieDomain,
      });

      window.location.pathname = normalizedNew;
    }
  };

  if (!isArray(locales) || locales.length < 2) {
    return null;
  }

  return (
    <Dropdown
      toggleComponent={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <LanguageIcon className="dropdown-icon svg-planet" />
          <span>{locale.attributes.name}</span>
          <DropdownArrow />
        </div>
      }
      placement="bottom-end"
      className="dropdown--languages"
      isVisible={isVisible}
      onVisibilityChange={(isVisible) => setIsVisible(isVisible)}
    >
      <DropdownContent>
        {locales.map((l) => (
          <DropdownItem
            key={l.attributes.locale}
            isActive={l.id === locale.id}
            onClick={() => handleChangeLocale(l)}
          >
            {l.attributes.name}
          </DropdownItem>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};
