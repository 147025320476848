import { parse, HTMLElement, TextNode, Node } from "node-html-parser";

export const injectReadMore = (htmlContent: string, maxLength = 1000) => {
  const root = parse(htmlContent);

  convertBulletsToLists(root);

  // return original content if "read more" controls are already present.
  if (root.querySelector(".more-content") || root.querySelector(".read-more")) {
    return htmlContent;
  }

  const [visibleContent, hiddenContent, , hasTruncated] = processNode(
    root,
    maxLength
  );

  // if no truncation occurred, return original content.
  if (!hasTruncated) {
    return htmlContent;
  }

  const visibleHtml = visibleContent?.toString() || "";
  const hiddenHtml = hiddenContent?.toString() || "";

  // return the modified html with "read more" functionality.
  return `
    ${visibleHtml}
    <div class="more-content" style="display: none;">${hiddenHtml}</div>
    <span class="read-more" data-more-text="Read more" data-less-text="Read less">Read more</span>
  `;
};

const convertBulletsToLists = (root: HTMLElement) => {
  // regex to match bullet markers (literal or alias forms).
  const bulletRegex = /^\s*((?:[•◾◦▪]|&bull;|&#8226;))/;

  // map bullet markers to a level.
  const bulletLevels: Record<string, number> = {
    "•": 1,
    "◾": 1,
    "&bull;": 1,
    "&#8226;": 1,
    "◦": 2,
    "▪": 2,
  };

  // helper function: Build a nested UL structure from bullet lines.
  const buildNestedList = (lines: string[]): HTMLElement => {
    const rootUl = new HTMLElement("ul", {});
    // stack holds the current UL and its bullet level.
    const stack: { ul: HTMLElement; level: number }[] = [];
    // start with a dummy container at level 0.
    stack.push({ ul: rootUl, level: 0 });

    lines.forEach((line) => {
      const match = line.match(bulletRegex);

      if (match) {
        const bullet = match[1];
        // remove the bullet marker and trim whitespace.
        const text = line.replace(bulletRegex, "").trim();
        // determine the bullet's level; default to 1.
        const currentLevel = bulletLevels[bullet] || 1;

        // pop from the stack until the top level is lower than current bullet.
        while (stack.length && stack[stack.length - 1].level >= currentLevel) {
          stack.pop();
        }

        // the parent UL is now on top of the stack.
        const parentUl = stack[stack.length - 1].ul;
        // create a new list item with the bullet text.
        const li = new HTMLElement("li", {});

        li.set_content(text);
        parentUl.appendChild(li);

        // create a new UL for potential nested items inside this li.
        const newUl = new HTMLElement("ul", {});

        li.appendChild(newUl);
        // push the new container with the current bullet level.
        stack.push({ ul: newUl, level: currentLevel });
      }
    });

    // cleanup: remove any empty ul elements.
    const cleanupEmptyUl = (el: HTMLElement) => {
      el.childNodes.forEach((child) => {
        if (
          child instanceof HTMLElement &&
          child.tagName.toLowerCase() === "ul" &&
          child.childNodes.length === 0
        ) {
          child.remove();
        } else if (child instanceof HTMLElement) {
          cleanupEmptyUl(child);
        }
      });
    };

    cleanupEmptyUl(rootUl);
    return rootUl;
  };

  // process every paragraph in the document.
  const paragraphs = root.querySelectorAll("p");
  paragraphs.forEach((p) => {
    // check if this paragraph has bullet markers anywhere.
    if (
      bulletRegex.test(p.innerHTML) ||
      /<br\s*\/?>\s*(?:[•◾◦▪]|&bull;|&#8226;)/.test(p.innerHTML)
    ) {
      // split the paragraph by <br> tags.
      const lines = p.innerHTML.split(/<br\s*\/?>/i);

      // separate any header content from bullet lines.
      const headerLines: string[] = [];
      const bulletLines: string[] = [];
      let bulletStarted = false;

      lines.forEach((line) => {
        // if bullet list hasn't started and this line (trimmed) starts with a bullet marker…
        if (!bulletStarted && bulletRegex.test(line.trim())) {
          bulletStarted = true;
          bulletLines.push(line);
        } else if (bulletStarted) {
          // once bullet lines begin, add all subsequent lines to bulletLines.
          bulletLines.push(line);
        } else {
          headerLines.push(line);
        }
      });

      // only modify the paragraph if there are bullet lines.
      if (bulletLines.length > 0) {
        // create a container to hold both header (if any) and the bullet list.
        const container = new HTMLElement("p", {});

        // if there is header text, add it as a separate paragraph.
        if (headerLines.length > 0 && headerLines.join("").trim() !== "") {
          const headerP = new HTMLElement("p", {});

          headerP.set_content(headerLines.join("<br>"));
          container.appendChild(headerP);
        }

        // build the nested list from bulletLines.
        const nestedList = buildNestedList(bulletLines);

        container.appendChild(nestedList);
        // replace the original paragraph with the new container.
        p.replaceWith(container);
      }
    }
  });
};

// helper function to find the last valid sentence boundary.
const findLastSentenceBoundary = (text: string, maxLength: number) => {
  const truncatedText = text.slice(0, maxLength);
  const lastPunctuationIndex = Math.max(
    truncatedText.lastIndexOf("."),
    truncatedText.lastIndexOf("!"),
    truncatedText.lastIndexOf("?")
  );

  return lastPunctuationIndex > 0
    ? lastPunctuationIndex + 1
    : findLastWordBoundary(truncatedText);
};

// helper function to find the last valid word boundary (space).
const findLastWordBoundary = (text: string) => {
  const lastSpaceIndex = text.lastIndexOf(" ");

  return lastSpaceIndex > 0 ? lastSpaceIndex : text.length;
};

// truncate a textnode without breaking words.
const truncateTextNode = (
  textNode: TextNode,
  remainingChars: number
): [TextNode | null, TextNode | null, number, boolean] => {
  const textContent = textNode.text;

  if (textContent.length > remainingChars) {
    const truncateAt = findLastSentenceBoundary(textContent, remainingChars);
    const truncatedText = textContent.slice(0, truncateAt).trim();
    const hiddenText = textContent.slice(truncateAt).trim();

    const visibleNode = new TextNode(truncatedText);
    const hiddenNode = new TextNode(hiddenText);

    return [visibleNode, hiddenNode, 0, true];
  }

  return [textNode, null, remainingChars - textContent.length, false];
};

// recursively process html nodes.
const processNode = (
  node: Node,
  remainingChars: number
): [
  HTMLElement | TextNode | null,
  HTMLElement | TextNode | null,
  number,
  boolean
] => {
  // if node is neither htmlelement nor textnode (e.g. commentnode), convert it to a textnode.
  if (!(node instanceof HTMLElement) && !(node instanceof TextNode)) {
    const converted = new TextNode(node.toString());

    return truncateTextNode(converted, remainingChars);
  }

  // if node is a textnode, process it.
  if (node instanceof TextNode) {
    return truncateTextNode(node, remainingChars);
  }

  // now node is an htmlelement.
  const nonBreakableTags = ["strong", "b", "ul", "li"];

  // ensure tagname exists before calling toLowerCase.
  if (node.tagName && nonBreakableTags.includes(node.tagName.toLowerCase())) {
    if (remainingChars <= 0) {
      return [null, node.clone() as HTMLElement, remainingChars, true];
    }

    const nodeLength = node.textContent.length;

    if (nodeLength <= remainingChars) {
      return [
        node.clone() as HTMLElement,
        null,
        remainingChars - nodeLength,
        false,
      ];
    }

    return [null, node.clone() as HTMLElement, remainingChars, true];
  }

  // for elements that can be broken up, clone and clear inner content.
  const visibleElement = node.clone() as HTMLElement;
  const hiddenElement = node.clone() as HTMLElement;

  visibleElement.innerHTML = "";
  hiddenElement.innerHTML = "";

  let hasTruncated = false;
  let remainingLength = remainingChars;

  node.childNodes.forEach((child) => {
    // if child is not htmlelement or textnode, convert it.
    if (!(child instanceof HTMLElement) && !(child instanceof TextNode)) {
      child = new TextNode(child.toString());
    }

    if (!hasTruncated) {
      const [visibleChild, hiddenChild, remaining, truncated] = processNode(
        child,
        remainingLength
      );

      if (visibleChild) {
        visibleElement.appendChild(visibleChild);
      }

      if (hiddenChild) {
        hiddenElement.appendChild(hiddenChild);
      }

      remainingLength = remaining;
      hasTruncated = truncated;
    } else {
      hiddenElement.appendChild(child.clone() as HTMLElement | TextNode);
    }
  });

  return [visibleElement, hiddenElement, remainingLength, hasTruncated];
};
