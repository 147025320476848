import { useCallback, useEffect, useState } from "react";

export const useMediaQuery = (width: number) => {
  const [matched, setMatched] = useState(false);

  const handleMatched = useCallback((e: MediaQueryListEvent) => {
    if (e.matches) {
      setMatched(true);
    } else {
      setMatched(false);
    }
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${width}px)`);

    mediaQuery.addEventListener("change", handleMatched);

    if (mediaQuery.matches) {
      setMatched(true);
    }

    return () => mediaQuery.removeEventListener("change", handleMatched);
  }, [width, handleMatched]);

  return matched;
};
