"use client";

import type { FC } from "react";
import { useState } from "react";
import {
  Dropdown,
  DropdownArrow,
  DropdownContent,
  DropdownItem,
} from "../Dropdown";
import { useCurrencies } from "@/contexts/currencies";
import { useSetCookie } from "cookies-next";
import { Currency } from "@/types";
import { useI18n } from "@/contexts/i18n";
import { I18nService } from "src/services/I18nService";
import { getCookieDomain, isArray, router } from "@/utils";

export const CurrencySwitcher: FC = () => {
  const { currencies, currency, setCurrency } = useCurrencies();
  const { locales, locale } = useI18n();
  const [isVisible, setIsVisible] = useState(false);
  const setCookie = useSetCookie();

  const handleChangeCurrency = (selectedCurrency: Currency) => {
    if (currency.id === selectedCurrency.id) {
      return;
    }

    setIsVisible(false);
    setCurrency(selectedCurrency);

    if (typeof window !== "undefined") {
      const cookieDomain = getCookieDomain(window.location.hostname);
      const i18nService = new I18nService(
        locales || [],
        currencies || [],
        window.location.href,
        locale.attributes.slug,
        selectedCurrency.attributes.code
      );

      const normalizedNew = router.normalizePath(i18nService.pathname);

      setCookie("user_currency", selectedCurrency.attributes.code, {
        domain: cookieDomain,
      });

      router.replace(normalizedNew, false);
    }
  };

  if (!isArray(currencies) || currencies.length < 2) {
    return null;
  }

  return (
    <Dropdown
      toggleComponent={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <span>{currency.attributes.symbol}</span>
          <span>{currency.attributes.code}</span>
          <DropdownArrow />
        </div>
      }
      placement="bottom-end"
      className="dropdown--currencies"
      isVisible={isVisible}
      onVisibilityChange={(isVisible) => setIsVisible(isVisible)}
    >
      <DropdownContent>
        {currencies.map((c) => (
          <DropdownItem
            key={c.attributes.code}
            isActive={c.id === currency.id}
            onClick={() => handleChangeCurrency(c)}
          >
            {c.attributes.code}
          </DropdownItem>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};
