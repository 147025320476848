import type { FC } from "react";
import { StepItemProps } from "./StepList.types";
import clsx from "clsx";
import styles from "./StepList.module.scss";

export const StepItem: FC<StepItemProps> = ({
  title,
  description,
  stepNumber,
  isActive = false,
  showTail = true,
  icon,
  iconActive,
  onClick,
}) => {
  return (
    <div
      className={`${styles["step-list__item"]} ${
        isActive ? styles["step-list__item--active"] : ""
      } step-list__item`}
      onClick={onClick}
    >
      {showTail && (
        <div
          className={clsx([
            styles["step-list__item-tail"],
            "step-list__item-tail",
          ])}
        ></div>
      )}
      <div className={styles["step-list__item-icon-wrapper"]}>
        {isActive && iconActive ? (
          iconActive
        ) : icon ? (
          <span className={styles["step-list__item-icon"]}>{icon}</span>
        ) : (
          <span className={styles["step-list__item-icon"]}>{stepNumber}</span>
        )}
      </div>
      <div className={styles["step-list__item-content"]}>
        <div className={styles["step-list__item-title"]}>{title}</div>
        {description && (
          <div className={styles["step-list__item-description"]}>
            {description}
          </div>
        )}
      </div>
    </div>
  );
};
