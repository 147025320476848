"use client";

import type { FC } from "react";
import { useEffect, useState, useRef } from "react";
import type { LazyImageLoaderProps } from "./Image.types";

export const LazyImageLoader: FC<LazyImageLoaderProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const observerExists =
      "MutationObserver" in window && "IntersectionObserver" in window;

    if (!observerExists) {
      setIsVisible(true);
      return;
    }

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry?.isIntersecting) {
            return;
          }

          setIsVisible(true);
          observerRef.current?.disconnect();
        });
      },
      {
        root: null,
        rootMargin: "500px",
        threshold: 0.1,
      }
    );

    observerRef.current.observe(containerRef.current);

    return () => {
      observerRef.current?.disconnect();
    };
  }, []);

  return children(isVisible, containerRef);
};
