import type { ImageProps } from "next/image";
import type { MediaItem, MediaPriority, ResponsiveMediaList } from "@/types";
import { isArray } from "@/utils";

export const normalizeProps = (
  props: ImageProps & { base64?: string },
  omitProps?: (keyof typeof props)[]
) => {
  const normalizedProps = { ...props };

  if ("srcset" in normalizedProps) {
    // @ts-expect-error: renaming "srcset" to "srcSet" to match the component's prop type
    normalizedProps.srcSet = normalizedProps.srcset;
    delete normalizedProps.srcset;
  }

  if (isArray(omitProps)) {
    omitProps.forEach((prop) => {
      delete normalizedProps[prop];
    });
  }

  if (normalizedProps?.base64) {
    normalizedProps.blurDataURL = normalizedProps.base64;
    normalizedProps.placeholder = "blur";

    delete normalizedProps.base64;
  }

  return normalizedProps;
};

export const setAltTextIfEmpty = (
  item: MediaItem,
  index: number,
  text: string
) => {
  if (!item.alt || item.alt.trim() === "") {
    let type = item?.type || "media";
    type = String(type).charAt(0).toUpperCase() + String(type).slice(1);

    return { ...item, alt: `${text} - ${type} ${index + 1}` };
  }

  return item;
};

export const filterMediaWithSrc = (media: MediaItem[]) => {
  const mediaArray = Array.isArray(media) ? media : [media];

  if (!mediaArray) {
    return [];
  }

  return mediaArray.filter((meida) => meida?.src && meida.src.trim() !== "");
};

export const prepareResponsiveMediaList = (
  mediaList?: ResponsiveMediaList,
  altText: string = "Media"
): ResponsiveMediaList => {
  const desktop = filterMediaWithSrc(mediaList?.desktop || []).map(
    (item, index) => setAltTextIfEmpty(item, index, altText)
  );
  const tablet = filterMediaWithSrc(mediaList?.tablet || desktop).map(
    (item, index) => setAltTextIfEmpty(item, index, altText)
  );
  const mobile = filterMediaWithSrc(mediaList?.mobile || tablet).map(
    (item, index) => setAltTextIfEmpty(item, index, altText)
  );

  return { desktop, tablet, mobile };
};

export const getFirstMedia = (
  mediaList?: ResponsiveMediaList,
  priority: MediaPriority = "desktop",
  altText: string = "Media"
): MediaItem | undefined => {
  const responsiveMediaList = prepareResponsiveMediaList(mediaList, altText);

  return responsiveMediaList[priority][0];
};
