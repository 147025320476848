"use client";

import type { FC, VideoHTMLAttributes } from "react";
import { useEffect, useRef, useMemo } from "react";
import type { VideoProps } from "./Video.types";
import { isArray, isEmptyArray } from "@/utils";
import { PlayButton } from "./PlayButton";
import styles from "./Video.module.scss";

const sanitizeSource = <T extends object, K extends keyof T>(
  source: T,
  keysToOmit: K[]
): Omit<T, K> => {
  const copy = { ...source };

  for (const key of keysToOmit) {
    delete copy[key];
  }

  return copy;
};

export const Video: FC<VideoProps> = ({ attributes = {}, sources = [] }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const options = useMemo(
    () => ({ root: null, rootMargin: "0px", threshold: 0.25 }),
    []
  );

  const { customControls, ...restAttributes } = attributes;

  const defaultAttributes: VideoHTMLAttributes<HTMLVideoElement> = useMemo(
    () => ({
      preload: "metadata",
      autoPlay: true,
      loop: true,
      muted: true,
      playsInline: true,
      controls: false,
      controlsList: "nodownload nofullscreen noremoteplayback",
      ...restAttributes,
    }),
    [restAttributes]
  );

  if (customControls) {
    defaultAttributes.controls = false;
  }

  const callbackObserver = (entries: IntersectionObserverEntry[]) => {
    if (!videoRef.current || typeof videoRef.current.play !== "function") {
      return;
    }

    const [entry] = entries;

    if (entry.isIntersecting) {
      void videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    if (!defaultAttributes.autoPlay) {
      return;
    }

    const observerExists =
      "MutationObserver" in window && "IntersectionObserver" in window;

    if (observerExists) {
      const observer = new IntersectionObserver(callbackObserver, options);
      const currentVideo = videoRef.current;

      if (currentVideo) {
        observer.observe(currentVideo);
      }

      return () => {
        if (currentVideo) {
          observer.unobserve(currentVideo);
        }
      };
    } else if (videoRef.current) {
      void videoRef.current.play();
    }
  }, [defaultAttributes.autoPlay, options]);

  if (!isArray(sources) || isEmptyArray(sources)) {
    return null;
  }

  return (
    <>
      {customControls ? (
        <div className={styles["video-container"]}>
          <video {...defaultAttributes} ref={videoRef}>
            {sources.map((source, index) => {
              const sanitizedSource = sanitizeSource(source, ["type"]);
              return <source key={index} {...sanitizedSource} />;
            })}
          </video>
          <PlayButton videoRef={videoRef} />
        </div>
      ) : (
        <video {...defaultAttributes} ref={videoRef}>
          {sources.map((source, index) => {
            const sanitizedSource = sanitizeSource(source, ["type"]);
            return <source key={index} {...sanitizedSource} />;
          })}
        </video>
      )}
    </>
  );
};

Video.displayName = "Video";
