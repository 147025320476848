import type {
  CurrenciesAction,
  CurrenciesContextState,
} from "./CurrenciesContext.types";

export const CurrenciesReducer = (
  state: CurrenciesContextState,
  action: CurrenciesAction
): CurrenciesContextState => {
  const { type, payload } = action;

  switch (type) {
    case "SET_CURRENCIES":
      return { ...state, currencies: payload };

    case "SET_CURRENCY":
      return { ...state, currency: payload };

    default:
      return state;
  }
};
