"use client";

import type { FC } from "react";
import { useCallback, useMemo, useReducer } from "react";
import { CurrenciesContext, defaultCurrenciesState } from "./CurrenciesContext";
import { CurrenciesReducer } from "./CurrenciesReducer";
import type { CurrenciesProviderProps } from "./CurrenciesContext.types";
import type { Currency } from "@/types";

export const CurrenciesProvider: FC<CurrenciesProviderProps> = ({
  currencies,
  currency,
  defaultCurrency,
  children,
}) => {
  const overrides = Object.fromEntries(
    Object.entries({
      currencies,
      currency,
      defaultCurrency,
    }).filter(([, value]) => value !== undefined)
  );

  const [state, dispatch] = useReducer(CurrenciesReducer, {
    ...defaultCurrenciesState,
    ...overrides,
  });

  const setCurrencies = useCallback((currencies: Currency[]) => {
    dispatch({ type: "SET_CURRENCIES", payload: currencies });
  }, []);

  const setCurrency = useCallback((currency: Currency) => {
    dispatch({ type: "SET_CURRENCY", payload: currency });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setCurrencies,
      setCurrency,
    }),
    [state, setCurrencies, setCurrency]
  );

  return (
    <CurrenciesContext.Provider value={contextValue}>
      {children}
    </CurrenciesContext.Provider>
  );
};
