"use client";

import type { FC } from "react";
import { useCallback, useMemo, useReducer } from "react";
import { AccordionContext, defaultAccordionState } from "./AccordionContext";
import { AccordionReducer } from "./AccordionReducer";
import type { AccordionProviderProps } from "./AccordionContext.types";

export const AccordionProvider: FC<AccordionProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(AccordionReducer, {
    ...defaultAccordionState,
  });

  const toggleAccordion = useCallback((id: string | null) => {
    dispatch({ type: "TOGGLE_ACCORDION", payload: id });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      toggleAccordion,
    }),
    [state, toggleAccordion]
  );

  return (
    <AccordionContext.Provider value={contextValue}>
      {children}
    </AccordionContext.Provider>
  );
};
