let body: HTMLElement | null = null;
let isLocked = false;
let scrollPosition = 0;

if (typeof document !== "undefined") {
  body = document.querySelector("body");
}

export const scroll = {
  lock() {
    if (isLocked || !body) {
      return;
    }

    scrollPosition = window.scrollY;
    body.style.overflowY = "scroll";
    body.style.position = "fixed";
    body.style.top = `-${scrollPosition}px`;
    body.style.width = "100%";
    isLocked = true;
  },
  unlock() {
    if (!body) {
      return;
    }

    const isLockedMultiple =
      document.querySelectorAll(".modal-window.modal-window--displayed")
        ?.length > 1;

    if (isLockedMultiple || !isLocked) {
      return;
    }

    body.style.removeProperty("overflow-y");
    body.style.removeProperty("position");
    body.style.removeProperty("top");
    body.style.removeProperty("width");
    window.scrollTo(0, scrollPosition);
    isLocked = false;
  },
  scrollTo(element: HTMLElement) {
    if (typeof window === "undefined" || !element) {
      return;
    }

    const headerHeight =
      document.getElementsByTagName("header")[0]?.offsetHeight || 0;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset - headerHeight;

    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
  },
};
