import type { AppAction, AppContextState } from "./AppContext.types";

export const AppReducer = (
  state: AppContextState,
  action: AppAction
): AppContextState => {
  const { type, payload } = action;

  switch (type) {
    case "SET_APP":
      return { ...state, app: payload };

    case "SET_IS_LOADING":
      return { ...state, isLoading: payload };

    default:
      return state;
  }
};
