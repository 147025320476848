import type { FC } from "react";
import type { PlaceholderProps } from "./Image.types";

export const Placeholder: FC<PlaceholderProps> = ({
  width,
  height,
  blurDataURL,
  ref,
}) => (
  <div
    ref={ref}
    className="image-placeholder"
    style={{
      background: blurDataURL ? `url(${blurDataURL})` : "var(--text-hint)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      filter: "blur(5px)",
      width: width ? `${width}px` : "inherit",
      height: height ? `${height}px` : "inherit",
      maxWidth: "100%",
      maxHeight: "100%",
    }}
  />
);
