export const getCookieDomain = (host: string): string | undefined => {
  const domain = process.env.NEXT_PUBLIC_DOMAIN || "";

  return host.includes(domain) ? `.${domain}` : undefined;
};

export const isWhiteLabelDomain = (host: string): boolean => {
  const domain = process.env.NEXT_PUBLIC_DOMAIN || "";

  return !host.includes(domain) ? true : false;
};
