import type { FC } from "react";
import type { AccordionProps } from "./Accordion.types";
import { AccordionProvider } from "./context";

export const Accordion: FC<AccordionProps> = ({ children }) => {
  return (
    <AccordionProvider expandedId={null}>
      <div className="accordion-container">{children}</div>
    </AccordionProvider>
  );
};
