"use client";

import { Suspense, useEffect } from "react";
import nprogress from "nprogress";
import { usePathname, useSearchParams } from "next/navigation";
import { NProgressProps } from "./NProgress.types";
import "./styles.css";

const Innards = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    nprogress.done();
  }, [pathname, searchParams]);

  return null;
};

export const NProgress = ({ options }: NProgressProps) => {
  useEffect(() => {
    if (options) {
      nprogress.configure(options);
    }
  }, [options]);

  return (
    <Suspense fallback={null}>
      <Innards />
    </Suspense>
  );
};
