"use client";

import type { FC } from "react";
import { APIProvider } from "@vis.gl/react-google-maps";
import { GoogleMapWrapper } from "./GoogleMapWrapper";
import type { GoogleMapProps } from "./GoogleMap.types";

export const GoogleMap: FC<GoogleMapProps> = ({
  markers = [],
  activeMarker = 0,
  activeMarkerCallback,
}) => {
  const apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

  if (!apiKey) {
    return null;
  }

  return (
    <APIProvider apiKey={apiKey}>
      <div className="google-map">
        <GoogleMapWrapper
          markers={markers}
          activeMarker={activeMarker}
          activeMarkerCallback={activeMarkerCallback}
        />
      </div>
    </APIProvider>
  );
};
