/**
 * checks if the given value is an empty string or, optionally, throws an error for non-string values.
 *
 * @param value - the value to check.
 * @param options - optional settings.
 * @param options.strict - if true, non-string values will cause an error.
 *
 * @returns true if the value is an empty string; false otherwise.
 */
export const isEmptyString = (
  value: unknown,
  options?: { strict?: boolean }
): boolean => {
  if (typeof value !== "string") {
    if (options?.strict) {
      throw new Error("input value must be a string.");
    }

    return true;
  }

  return value.trim().length === 0;
};
