import {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
  RefObject,
} from "react";

export interface Position {
  top: number;
  left: number;
  minWidth?: number;
}

export type Placement =
  | "top-start"
  | "top"
  | "top-end"
  | "right-start"
  | "right"
  | "right-end"
  | "bottom-end"
  | "bottom"
  | "bottom-start"
  | "left-end"
  | "left"
  | "left-start";

const hasTransformParent = (
  element: HTMLElement | null
): HTMLElement | null => {
  while (element && element !== document.body) {
    const style = window.getComputedStyle(element);

    if (
      style.transform !== "none" ||
      style.backdropFilter !== "none" ||
      style.filter !== "none"
    ) {
      return element;
    }

    element = element.parentElement;
  }

  return null;
};

export const usePositioning = (
  triggerRef: RefObject<HTMLElement | null>,
  contentRef: RefObject<HTMLElement | null>,
  isVisible: boolean,
  offset: number = 8,
  placement: Placement = "bottom"
): Position => {
  const [position, setPosition] = useState<Position>({ top: 0, left: 0 });

  const calculatePosition = useCallback(() => {
    if (triggerRef.current && contentRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const isRTL = document.documentElement.dir === "rtl";

      let leftPosition = triggerRect.left;
      let topPosition = triggerRect.bottom + offset;

      switch (placement) {
        case "bottom-start":
          topPosition = triggerRect.bottom + offset;
          leftPosition = isRTL
            ? triggerRect.right - contentRect.width
            : triggerRect.left;
          break;

        case "bottom-end":
          topPosition = triggerRect.bottom + offset;
          leftPosition = isRTL
            ? triggerRect.left
            : triggerRect.right - contentRect.width;
          break;

        case "bottom":
          topPosition = triggerRect.bottom + offset;
          leftPosition =
            triggerRect.left + (triggerRect.width - contentRect.width) / 2;
          break;

        case "top-start":
          topPosition = triggerRect.top - contentRect.height - offset;
          leftPosition = isRTL
            ? triggerRect.right - contentRect.width
            : triggerRect.left;
          break;

        case "top-end":
          topPosition = triggerRect.top - contentRect.height - offset;
          leftPosition = isRTL
            ? triggerRect.left
            : triggerRect.right - contentRect.width;
          break;

        case "top":
          topPosition = triggerRect.top - contentRect.height - offset;
          leftPosition =
            triggerRect.left + (triggerRect.width - contentRect.width) / 2;
          break;

        case "left-start":
          topPosition = triggerRect.top;
          leftPosition = triggerRect.left - contentRect.width - offset;
          break;

        case "left-end":
          topPosition = triggerRect.bottom - contentRect.height;
          leftPosition = triggerRect.left - contentRect.width - offset;
          break;

        case "left":
          topPosition =
            triggerRect.top + (triggerRect.height - contentRect.height) / 2;
          leftPosition = triggerRect.left - contentRect.width - offset;
          break;

        case "right-start":
          topPosition = triggerRect.top;
          leftPosition = triggerRect.right + offset;
          break;

        case "right-end":
          topPosition = triggerRect.bottom - contentRect.height;
          leftPosition = triggerRect.right + offset;
          break;

        case "right":
          topPosition =
            triggerRect.top + (triggerRect.height - contentRect.height) / 2;
          leftPosition = triggerRect.right + offset;
          break;
      }

      const transformParent = hasTransformParent(triggerRef.current);

      if (transformParent) {
        const containerRect = transformParent.getBoundingClientRect();
        leftPosition -= containerRect.left;
        topPosition -= containerRect.top;
      }

      const rightEdgeDistance =
        windowWidth - (leftPosition + contentRect.width);
      const bottomEdgeDistance =
        windowHeight - (topPosition + contentRect.height);

      if (rightEdgeDistance < 0) {
        leftPosition = Math.max(
          offset,
          windowWidth - contentRect.width - offset
        );
      }

      if (leftPosition < 0) {
        leftPosition = offset;
      }

      if (bottomEdgeDistance < 0) {
        topPosition = Math.max(
          offset,
          windowHeight - contentRect.height - offset
        );
      }

      if (topPosition < 0) {
        topPosition = offset;
      }

      setPosition((prev) => {
        if (
          prev.top === topPosition &&
          prev.left === leftPosition &&
          prev.minWidth === triggerRect.width
        ) {
          return prev;
        }

        return {
          top: topPosition,
          left: leftPosition,
          minWidth: triggerRect.width,
        };
      });
    }
  }, [triggerRef, contentRef, offset, placement]);

  useLayoutEffect(() => {
    if (isVisible) {
      calculatePosition();
    }
  }, [isVisible, calculatePosition]);

  useEffect(() => {
    if (isVisible) {
      let animationFrameId: number;

      const handleResize = () => {
        cancelAnimationFrame(animationFrameId);
        animationFrameId = requestAnimationFrame(() => {
          calculatePosition();
        });
      };

      const handleScroll = () => {
        cancelAnimationFrame(animationFrameId);
        animationFrameId = requestAnimationFrame(() => {
          calculatePosition();
        });
      };

      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("scroll", handleScroll);
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [isVisible, calculatePosition]);

  useEffect(() => {
    if (isVisible) {
      const observer = new ResizeObserver(() => {
        calculatePosition();
      });

      const contentElement = contentRef.current;

      if (contentElement) {
        observer.observe(contentElement);
      }

      return () => {
        if (contentElement) {
          observer.unobserve(contentElement);
        }
      };
    }
  }, [isVisible, contentRef, calculatePosition]);

  return position;
};
