import { I18nAction, I18nContextState } from "./I18nContext.types";

export const I18nReducer = (
  state: I18nContextState,
  action: I18nAction
): I18nContextState => {
  const { type, payload } = action;

  switch (type) {
    case "SET_LOCALES":
      return { ...state, locales: payload };

    case "SET_LOCALE":
      return { ...state, locale: payload };

    case "SET_TRANSLATIONS":
      return { ...state, translations: payload };

    default:
      return state;
  }
};
