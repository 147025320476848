import { RefObject, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 *
 * @param ref - React ref object to the component we are monitoring
 * @param onOutsideClick - Function to call when a click outside is detected
 */
export const useOutsideClick = (
  ref: RefObject<HTMLDivElement | null>,
  onOutsideClick: () => void
) => {
  useEffect(() => {
    if (typeof onOutsideClick !== "function") {
      console.error(
        "Expected onOutsideClick to be a function, but received",
        typeof onOutsideClick
      );

      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};
