import { createContext, useContext } from "react";
import type {
  SliderContextProps,
  SliderContextState,
} from "./SliderContext.types";

export const defaultSliderState: SliderContextState = {
  swiper: undefined,
};

export const SliderContext = createContext<SliderContextProps | null>(null);

export const useSlider = () => {
  const context = useContext<SliderContextProps | null>(SliderContext);

  if (!context) {
    throw new Error("useSlider must be used within a SliderProvider");
  }

  return context;
};
