"use client";

import type { FC } from "react";
import { useState, useEffect } from "react";
import type { SliderNavigationProps } from "./Slider.types";
import { useSlider } from "./context";
import clsx from "clsx";
import "swiper/css/bundle";
import "./styles.css";
import styles from "./Slider.module.scss";

export const SliderNavigation: FC<SliderNavigationProps> = ({
  variant = "rectangle",
  disabled = false,
}) => {
  const { swiper } = useSlider();
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const classMap = {
    none: "",
    circle: "swiper-nav--circle",
    rectangle: "swiper-nav--rectangle",
  };
  const navVariantClass = classMap[variant] || classMap["rectangle"];

  const slidePrev = () => {
    swiper?.slidePrev();
  };

  const slideNext = () => {
    swiper?.slideNext();
  };

  useEffect(() => {
    if (!swiper) {
      return;
    }

    const updateButtonStates = () => {
      setIsPrevDisabled(swiper.isBeginning && !swiper.params.loop);
      setIsNextDisabled(swiper.isEnd && !swiper.params.loop);
    };

    updateButtonStates();

    swiper.on("slideChange", updateButtonStates);

    return () => {
      swiper.off("slideChange", updateButtonStates);
    };
  }, [swiper]);

  if (disabled || !swiper) {
    return null;
  }

  return (
    <>
      <div
        className={clsx(
          styles["swiper-nav"],
          styles["swiper-nav--prev"],
          styles[navVariantClass],
          "swiper-nav",
          "swiper-nav--prev",
          navVariantClass,
          {
            [styles["swiper-nav--is-hidden"]]: isPrevDisabled,
            "swiper-nav--is-hidden": isPrevDisabled,
          }
        )}
        onClick={slidePrev}
      />
      <div
        className={clsx(
          styles["swiper-nav"],
          styles["swiper-nav--next"],
          styles[navVariantClass],
          "swiper-nav",
          "swiper-nav--next",
          navVariantClass,
          {
            [styles["swiper-nav--is-hidden"]]: isNextDisabled,
            "swiper-nav--is-hidden": isNextDisabled,
          }
        )}
        onClick={slideNext}
      />
    </>
  );
};
