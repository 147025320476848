import type { FC } from "react";
import type { AccordionDetailsProps } from "./Accordion.types";
import styles from "./Accordion.module.scss";

export const AccordionDetails: FC<AccordionDetailsProps> = ({ children }) => {
  return (
    <div className={styles["accordion-wrapper"]}>
      <div className={styles["accordion-details"]}>{children}</div>
    </div>
  );
};
