import type { FC, MouseEvent } from "react";
import { useEffect, useState } from "react";
import styles from "./Video.module.scss";
import type { PlayButtonProps } from "./Video.types";
import { PauseCircleIcon, PlayCircleIcon } from "../Icon";

export const PlayButton: FC<PlayButtonProps> = ({ videoRef }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!videoRef?.current) {
      return;
    }

    if (videoRef.current.paused) {
      void videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    const video = videoRef?.current;

    if (!video) {
      return;
    }

    const handleVideoEnd = () => {
      setIsPlaying(false);
    };

    video.addEventListener("ended", handleVideoEnd);

    return () => {
      video.removeEventListener("ended", handleVideoEnd);
    };
  }, [videoRef]);

  return (
    <button onClick={togglePlay} className={styles["play-button"]}>
      {isPlaying ? <PauseCircleIcon /> : <PlayCircleIcon />}
    </button>
  );
};
