import type { FC, MouseEvent } from "react";
import type { ButtonProps } from "./Button.types";
import { Link, Image, useModal, Iframe } from "@/components/common";
import { isEmptyString } from "@/utils";
import { useTranslation } from "@/contexts/i18n";
import { v4 as uuidv4 } from "uuid";
import { getFirstMedia } from "../Image/utils";
import styles from "./Button.module.scss";

const AdditionalText: FC<{ text: string }> = ({ text = "" }) => {
  const { t } = useTranslation();

  if (isEmptyString(text)) {
    return null;
  }

  return (
    <div
      className={`${styles["button__text--additional"]} button__text--additional`}
    >
      {t(text)}
    </div>
  );
};

export const Button: FC<ButtonProps> = ({
  link,
  text,
  additionalText = "",
  target = "_self",
  images,
  attributes = {},
  style = "primary",
  onClick,
}) => {
  const { t } = useTranslation();
  const { addModal } = useModal();
  const modalId = uuidv4();
  const image = getFirstMedia(images);

  const handleOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (target === "iframe") {
      event.preventDefault();

      addModal({
        id: modalId,
        content: <Iframe src={link} />,
      });
    }

    if (typeof onClick === "function") {
      onClick(event);
    }
  };

  if (isEmptyString(link) && isEmptyString(text)) {
    return null;
  }

  return (
    <Link
      href={link}
      className={`${styles.button} button ${styles[`button--${style}`]}`}
      onClick={handleOnClick}
      target={target}
      {...attributes}
    >
      {image && <Image {...image} />}
      <div className={`${styles["button__text-wrapper"]} button__text-wrapper`}>
        <AdditionalText text={additionalText} />
        <div className={`${styles["button__text"]} button__text`}>
          {t(text)}
        </div>
      </div>
    </Link>
  );
};
