import type {
  ModalAction,
  ModalContextState,
  ModalProps,
} from "./ModalContext.types";

export const ModalReducer = (
  state: ModalContextState,
  action: ModalAction
): ModalContextState => {
  const { type, payload } = action;

  switch (type) {
    case "ADD_MODAL":
      return { ...state, modals: [...state.modals, payload] };

    case "REMOVE_MODAL":
      return {
        ...state,
        modals: [
          ...state.modals.filter((modal: ModalProps) => modal.id != payload),
        ],
      };

    default:
      return state;
  }
};
