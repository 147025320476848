/**
 * checks if the given value is an empty array or, optionally, throws an error for non-array values.
 *
 * @param value - the value to check.
 * @param options - optional settings.
 * @param options.strict - if true, non-array values will cause an error.
 *
 * @returns true if the value is an empty array; false otherwise.
 */
export const isEmptyArray = (
  value: unknown,
  options?: { strict?: boolean }
): boolean => {
  if (!isArray(value)) {
    if (options?.strict) {
      throw new Error("input value must be an array.");
    }

    return true;
  }

  return value.length === 0;
};

export const isArray = (value: unknown): value is unknown[] => {
  return Array.isArray(value);
};

export function uniqueBy<T, K extends keyof T>(array: T[], field: K): T[] {
  if (!isArray(array)) {
    throw new Error("input must be an array.");
  }

  const seen = new Set<T[K]>();

  return array.filter((item) => {
    const key = item[field];

    if (seen.has(key)) {
      return false;
    }

    seen.add(key);

    return true;
  });
}
