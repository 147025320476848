"use client";

import { useEffect, useCallback, useRef } from "react";
import { usePathname, useSearchParams } from "next/navigation";

const extractIdsFromUrl = (
  url: URL
): { blockId: string | null; eventId: string | null } => {
  let blockId =
    url.searchParams.get("block-id") || url.searchParams.get("block");
  let eventId =
    url.searchParams.get("event-id") || url.searchParams.get("event");

  if (!blockId && url.hash) {
    const match = url.hash.match(/block(?:-id)?-(\d+)/);
    blockId = match?.[1] || null;
  }
  if (!eventId && url.hash) {
    const match = url.hash.match(/event(?:-id)?-(\d+)/);
    eventId = match?.[1] || null;
  }

  return { blockId, eventId };
};

export const Anchor = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const headerHeightRef = useRef<number>(0);

  useEffect(() => {
    const header = document.querySelector("header");
    headerHeightRef.current = header ? header.offsetHeight - 20 : 0;
  }, []);

  const scrollToBlock = useCallback(
    (blockId: string | null, eventId: string | null) => {
      if (!blockId && !eventId) {
        return;
      }

      let block: Element | null = null;

      if (blockId) {
        block =
          document.querySelector(`[data-block-id="${blockId}"]`) ||
          document.querySelector(`#block-${blockId}`) ||
          document.querySelector(`#block-id-${blockId}`);
      }

      if (eventId) {
        const eventBlock =
          block?.querySelector(`[data-event-id="${eventId}"]`) ||
          document.querySelector(`[data-event-id="${eventId}"]`) ||
          document.querySelector(`#event-${eventId}`) ||
          document.querySelector(`#event-id-${eventId}`);

        if (eventBlock) {
          block = eventBlock;
        }
      }

      if (block) {
        const blockTop = block.getBoundingClientRect().top + window.pageYOffset;

        window.scrollTo({
          top: blockTop - headerHeightRef.current,
          behavior: "smooth",
        });
      }
    },
    []
  );

  useEffect(() => {
    const handleAnchorClick = (event: MouseEvent) => {
      const anchor = (event.target as HTMLElement).closest("a");

      if (!anchor) {
        return;
      }

      const href = anchor.href;
      const url = new URL(href, location.origin);

      if (location.hostname === url.hostname) {
        const { blockId, eventId } = extractIdsFromUrl(url);

        if (blockId || eventId) {
          event.preventDefault();
          scrollToBlock(blockId, eventId);
        }
      }
    };

    document.addEventListener("click", handleAnchorClick);

    return () => {
      document.removeEventListener("click", handleAnchorClick);
    };
  }, [scrollToBlock]);

  useEffect(() => {
    const { blockId, eventId } = extractIdsFromUrl(
      new URL(window.location.href)
    );

    scrollToBlock(blockId, eventId);
  }, [pathname, searchParams, scrollToBlock]);

  return null;
};
